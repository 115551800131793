import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our Enjin Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  QR Scan
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Creator Ratings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Royalty Payments
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Proven Security
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Multi-Lingual
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Modern Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Social Profiles & Chats
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Showcase
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Admin Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Features Request
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">QR Scan
                        </h4>
                        <p className="pharagraph">New users can scan the QR code and install the Enjin wallet app on Android & <span className="transNone">i</span>OS. Once installed, free NFT will be rewarded! Using that, new users can list them on the marketplace for bid. This helps the newbies to get an idea about the NFTs.
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="275px" height="325px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/qr-scan.webp" alt="QR Scan" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Creator Ratings
                        </h4>
                        <p className="pharagraph">Our Enjin clone script comes with the feature of rating the creators based on their performance and creativities which helps the buyers to choose the most popular ones.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="304px" height="267px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/creator-ratings.webp" alt="Creator Ratings" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Royalty Payments </h4>
                        <p className="pharagraph">Our Enjin clone script is developed in a way where the creators will get their royalty payment support and ownership proof for creating new NFTs.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="211px" height="290px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/royalty-payments.webp" alt="Royalty Payments" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Proven Security
                        </h4>
                        <p className="pharagraph">We don’t compromise on any part of the security features. Our Enjin clone script has successfully passed many advanced security tests to ensure the safety of the customer funds.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="338px" height="327px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/proven-security.webp" alt="Proven Security" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-Lingual
                        </h4>
                        <p className="pharagraph">One of the bonus features our Enjin clone script has is the support of global languages that help to increase the platform audience participation around the world.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="323px" height="272px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/multilingual-support.webp" alt="Multi-Lingual" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Modern Dashboard
                        </h4>
                        <p className="pharagraph">Our Enjin user dashboard is easy to use and manage. Users will be drawn to the game-like features and abilities to level up, gain points, and receive awards, by joining in on the discussion.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="400px" height="250px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/modern-dashboard.webp" alt="Modern Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Social Profiles & Chats
                        </h4>
                        <p className="pharagraph">Every member gets a profile, messaging system, activity wall and much more. It serves as a place where they can keep up with all their discussions, reactions, applications and points. Website awards can be proudly displayed, and much more!</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw315" width="310px" height="274px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/social-profiles-chats.webp" alt="Social Profiles & Chats" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Showcase
                        </h4>
                        <p className="pharagraph">Explore the showcase section in our Enjin clone script to take a deep dive into how businesses and developers create successful NFT-powered experiences, and learn how to follow in their footstepsExplore the showcase section in our Enjin clone script to take a deep dive into how businesses and developers create successful NFT-powered experiences, and learn how to follow in their footsteps
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="432px" height="281px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/showcase.webp" alt="Showcase" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Dashboard 
                        </h4>
                        <p className="pharagraph">The admin can be able to manage the entire functionalities through the powerful admin portal we provide. Using that, the admin can track the user activities, their holdings, admin commission adjustments and much more.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="410px" height="256px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/admin-dashboard.webp" alt="Admin Dashboard " />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Features Request
                        </h4>
                        <p className="pharagraph">Customers can suggest to the admin/site owner what features can be added or improved in the current system. Admin can view them and include it if they’re suitable. Innovative ideas will be welcomed and rewarded. </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="332px" height="294px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/features-request.webp" alt="Features Request" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures